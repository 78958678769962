import axios from 'axios';
import { useAuthStore } from '../store/auth';
import tool_app from './tool_app';
import { useRouter } from 'vue-router';

let router = '';
let ORIGIN_URL = '';
const main_api = {
  invokeAPI(path, api) {
    return new Promise((resolve, reject) => {
      ORIGIN_URL = import.meta.env.VITE_API_URL;
      router = useRouter();
      const url = `${ORIGIN_URL}${path}`;

      const store = useAuthStore();
      try {
        tool_app.log('payload: ', JSON.stringify(api));
      } catch (e) {
        tool_app.log(e);
      }

      axios
        .post(url, api, {
          headers: {
            Authorization: store.token,
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          tool_app.log('response: ', res);
          console.log(res.data.Code);
          if (res.data.Code === 200) {
            resolve(res);
          } else if (res.data.Code === 401) {
            window.alert('토큰이 만료 되었습니다.\n다시 로그인해주세요.');
            store.logout();
            router.push('/auth/login');
          } else if (res.data.Code === 403) {
            window.alert('권한이 없습니다.\n관리자에게 문의해 주세요.');
            router.push(-1);
            reject(res);
          } else {
            resolve(res);
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  invokeAuthAPI(path, api) {
    return new Promise((resolve, reject) => {
      ORIGIN_URL = import.meta.env.VITE_API_URL;
      const url = `${ORIGIN_URL}${path}`;
      tool_app.log(`request path: ${url}`);

      axios
        .post(url, api, {
          headers: {
            'Content-Type': 'application/json',
          },
        })

        .then(res => {
          tool_app.log('response: ', res);
          if (res.data.Code === 200) {
            resolve(res);
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  },
};
export default main_api;
