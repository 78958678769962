const global_const = {
  projectStatus: [
    { value: 'scheduled', text: '예정' },
    { value: 'complete', text: '완료' },
    { value: 'cancel', text: '취소' },
    { value: 'noShow', text: '노쇼' },
    { value: 'reschedule', text: '연기' },
    { value: 'waiting', text: '대기' },
    { value: 'inProgress', text: '진행중' },
  ],
  statusColor: {
    scheduled: 'info',
    complete: 'success',
    cancel: 'error',
    noShow: 'warning',
    reschedule: 'primary',
    waiting: 'secondary',
    inProgress: 'primary',
  },
  sex: [
    {
      value: 'male',
      text: '남성',
    },
    {
      value: 'female',
      text: '여성',
    },
    {
      value: 'other',
      text: '기타',
    },
  ],
  marketingChannel: [
    { value: 'ameblo', text: '아메바' },
    { value: 'twitter', text: '트위터' },
    { value: 'instagram', text: '인스타그램' },
  ],
  reviewType: [
    { value: 'customer', text: '고객 후기' },
    { value: 'partner', text: '파트너 후기' },
  ],
  platforms: [{ value: 'gangnamunni', text: '강남언니' }],
  subject: [
    {
      value: 'dental',
      text: '치과',
    },
    {
      value: 'dermatology',
      text: '피부과',
    },
    {
      value: 'plastic_surgery',
      text: '성형외과',
    },
    {
      value: 'orthopedic',
      text: '정형외과/외과',
    },
    {
      value: 'hospital',
      text: '병원',
    },
    {
      value: 'pain_management',
      text: '마취통증의학과',
    },
    {
      value: 'neurology',
      text: '신경과/신경외과',
    },
    {
      value: 'rehabilitation',
      text: '재활의학과',
    },
    {
      value: 'internal_medicine',
      text: '내과',
    },
    {
      value: 'pediatrics',
      text: '소아청소년과',
    },
    {
      value: 'ophthalmology',
      text: '안과',
    },
    {
      value: 'otolaryngology',
      text: '이비인후과',
    },
    {
      value: 'psychiatry',
      text: '정신건강의학과',
    },
    {
      value: 'obstetrics_gynecology',
      text: '산부인과',
    },
    {
      value: 'urology',
      text: '비뇨기과',
    },
    {
      value: 'traditional_medicine',
      text: '한의원',
    },
    {
      value: 'general_practice',
      text: '일반의원',
    },
  ],
  cumulative_patient_counts: [
    { value: 1, text: '0~500' },
    { value: 2, text: '500+' },
    { value: 3, text: '1000+' },
    { value: 4, text: '2000+' },
    { value: 5, text: '3000+' },
    { value: 6, text: '5000+' },
    { value: 6, text: '10000+' },
  ],
  hospital_scales: [
    { value: 1, text: '의원', description: '병상 수 30개 미만, 주로 외래 환자 진료' },
    { value: 2, text: '병원', description: '병상 수 30개 이상, 입원 환자 진료' },
    { value: 3, text: '종합병원', description: '병상 수 100개 이상, 여러 진료 과목 운영' },
    { value: 4, text: '상급종합병원', description: '중증 질환 전문 치료, 병상 수 500개 이상' },
    { value: 5, text: '전문병원', description: '특정 질환 전문 치료, 보건복지부 지정 필요' },
  ],
  region: [
    { value: 'JP', text: '일본' },
    { value: 'CN', text: '중국' },
    { value: 'US', text: '미국' },
    { value: 'TH', text: '태국' },
    { value: 'MN', text: '몽골' },
    { value: 'VN', text: '베트남' },
    { value: 'PH', text: '필리핀' },
    { value: 'RU', text: '러시아' },
    { value: 'MY', text: '말레이시아' },
    { value: 'SG', text: '싱가포르' },
    { value: 'KH', text: '캄보디아' },
    { value: 'ID', text: '인도네시아' },
    { value: 'HK', text: '홍콩' },
    { value: 'TW', text: '대만' },
    { value: 'AE', text: '아랍에미리트' },
    { value: 'IN', text: '인도' },
  ],

  // 생년월일 select array
  // 지금 년도에서 100년 전까지의 생년월일을 만들어줌
  birthYear: Array.from({ length: 100 }, (v, i) => {
    return {
      value: new Date().getFullYear() - i,
      text: new Date().getFullYear() - i,
    };
  }),
  // 생월 1~12의 select array
  birthMonth: Array.from({ length: 12 }, (v, i) => {
    return {
      value: i + 1,
      text: i + 1,
    };
  }),
  // 생일 1~31까지의 select array
  birthDay: Array.from({ length: 31 }, (v, i) => {
    return {
      value: i + 1,
      text: i + 1,
    };
  }),
};

export default global_const;
