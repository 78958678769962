import global_const from './global_const';
import moment from 'moment';

const tool_app = {
  appname: 'medi-meet',
  DebugParameter: undefined,
  mUrl: '',
  mIsMobile: false,
  sCurrentState: null,
  test: function () {
    this.log('********************************');
    this.log(arguments);
  },
  isDebug: function () {
    if (document.domain.indexOf('localhost') >= 0) {
      return true;
    }
    if (document.domain.indexOf('127.0.0.1') >= 0) {
      return true;
    }
    if (document.domain.indexOf('192.168') >= 0) {
      return true;
    }
    try {
      if (process.env.ENV_NAME === 'dev') {
        return true;
      }
    } catch (e) {
      tool_app.log(e);
    }
    return false;
  },
  log: function () {
    let args = Array.prototype.slice.call(arguments);
    if (this.isDebug()) {
      console.log.apply(console, args);
    }
  },
  phoneFormatter: function (num, type) {
    let formatNum = '';

    if (!num) return '';

    if (num.length === 11) {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else if (num.length === 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
      if (num.indexOf('02') === 0) {
        if (type === 0) {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
        } else {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        }
      } else {
        if (type === 0) {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
      }
    }
    return formatNum;
  },
  numberWithCommas: function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  format_hour_min: function (pMin) {
    let time = '';
    if (pMin >= 60) {
      time += Math.floor(pMin / 60) + '시간';
    }
    if (pMin % 60 > 0) {
      if (time.length > 0) {
        time += ' ' + Math.floor(pMin % 60) + '분';
      } else {
        time += Math.floor(pMin % 60) + '분';
      }
    }
    return time;
  },
  setCookie: function (cookie_name, value, days) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + days); // 설정 일수만큼 현재시간에 만료값으로 지정
    let cookie_value = escape(value) + (days == null ? '' : '; expires=' + exdate.toUTCString());
    document.cookie = cookie_name + '=' + cookie_value;
  },
  getCookie: function (cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  get_list_text: function (list, key) {
    const item = list.find(item => item.value === key);
    return item ? item.text : '';
  },
  format_local_time: function (string) {
    let res = '';
    res = moment(string).format('YYYY/MM/DD HH:mm:ss');
    return res;
  },
  set_search_duration: function (day) {
    let _day = day ? day : 3;
    let before_date = moment().subtract('days', _day).startOf('day');
    let today = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);

    return { start_date: before_date.toDate(), end_date: today };
  },
  set_duration_monthty01: function () {
    //현재 기준일, 지난달 1일 부터 현재까지의 기간 셋팅
    let before_date = moment().subtract('months', 1).set('date', 1).startOf('day');
    let today = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);

    return { start_date: before_date.toDate(), end_date: today };
  },
  format_utcString: function (val) {
    return val + '+00:00';
  },
  deepCopy: function (obj) {
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch (e) {
      this.log(e);
      return null;
    }
  },
  downloadExcel: function (data) {
    // colum 필요 / item 핑요

    const wb = new ExcelJS.Workbook();

    let ws = wb.addWorksheet(
      Array.isArray(data.sheetname) && data.sheetname[0] ? data.sheetname[0] : 'sheet'
    );

    function getExcelColIndex(num) {
      let Binary = 26;
      let share = parseInt(num / Binary);
      let remainder = num % Binary;
      //앞파벳 A-Z , 65-90 총26자
      let _match = String.fromCharCode(65 + remainder);
      return '' + (share > 0 ? getExcelColIndex(share) : '') + _match;
    }

    data.cols.forEach(function (val, index) {
      let col_index = getExcelColIndex(index) + '1';
      let col = ws.getCell(col_index);
      col.value = val;
      col.alignment = { vertical: 'middle', horizontal: 'center' };
      col.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'fff1f1f1' },
      };
      col.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
      ws.getColumn(index + 1).width = 20;
      //cols_style 속성으로 속성 변경하게 만들기
    });

    data.items.forEach(function (colarr, rindex) {
      colarr.forEach(function (val, cindex) {
        let col_index = getExcelColIndex(cindex) + (rindex + 2);
        let col = ws.getCell(col_index);
        col.value = val;
        col.alignment = { vertical: 'middle', horizontal: 'center' };
        col.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    });

    wb.xlsx
      .writeBuffer({
        base64: false,
      })
      .then(function (xls64) {
        // build anchor tag and attach file (works in chrome)
        var a = document.createElement('a');
        var bb = new Blob([xls64], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        var url = URL.createObjectURL(bb);
        a.href = url;
        a.download = (data.filename ? data.filename : 'export') + '.xlsx';
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.message);
      });
  },
  formattingTimeRegular(temp) {
    let day = parseInt(temp / global_const.TIME.DAY);
    temp = temp - global_const.TIME.DAY * day;
    let hour = parseInt(temp / global_const.TIME.HOUR);
    temp = temp - global_const.TIME.HOUR * hour;
    let min = parseInt(temp / global_const.TIME.MIN);
    temp = temp - global_const.TIME.MIN * min;
    let sec = parseInt(temp / global_const.TIME.SEC);

    let str = '';
    if (day) str += day + '일 ';

    if (hour || day) str += hour + '시간 ';

    if (min || hour) str += min + '분 ';

    str += sec + '초';

    return str;
  },
  timeMinToHourFormat(temp) {
    let hour = parseInt(temp / 60);
    temp = temp - 60 * hour;
    let min = temp;

    let str = '';

    if (hour) str += hour + '시간';

    if (min) str += min + '분';

    return str;
  },
  async MakeExcelList(getList) {
    return new Promise(async function (resolve, reject) {
      try {
        let { totalcount, list } = await getList(true, 1);
        tool_app.log(totalcount);
        tool_app.log(list);
        let resList = [...list];
        let totalCalls = parseInt(totalcount / global_const.listRowLimit) + 1;

        for (let i = 2; i <= totalCalls; i++) {
          let { totalcount, list } = await getList(true, i);
          resList = [...resList, ...list];
        }
        resolve(resList);
      } catch (e) {
        reject(false);
      }
    });
  },
  /* 엑셀 다운로드시 행번호가 A-Z 밖에 안되는 이슈가 있어서
   * 새로운 function 만듦
   */
  downloadExcel_overRow(data) {
    const wb = new ExcelJS.Workbook();
    let ws = wb.addWorksheet(
      Array.isArray(data.sheetname) && data.sheetname[0] ? data.sheetname[0] : 'sheet'
    );

    function getExcelColIndex(num) {
      const Binary = 26;
      const share = Math.floor((num - 1) / Binary);
      const remainder = (num - 1) % Binary;
      let columnRef = '';

      if (share > 0) {
        columnRef += getExcelColIndex(share);
      }

      columnRef += String.fromCharCode(65 + remainder);

      return columnRef;
    }

    function getExcelCellRef(row, col) {
      const columnRef = getExcelColIndex(col);
      const rowRef = row.toString();
      return columnRef + rowRef;
    }

    data.cols.forEach(function (val, index) {
      const colIndex = getExcelColIndex(index + 1) + '1';
      const col = ws.getCell(colIndex);

      col.value = val;
      col.alignment = { vertical: 'middle', horizontal: 'center' };
      col.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'fff1f1f1' },
      };
      ws.getColumn(index + 1).width = 20;
    });

    data.items.forEach(function (colArr, rIndex) {
      colArr.forEach(function (val, cIndex) {
        const row = rIndex + 2;
        const col = cIndex + 1;
        const cellRef = getExcelCellRef(row, col);
        const cell = ws.getCell(cellRef);
        cell.value = val;
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
    });

    wb.xlsx
      .writeBuffer({ base64: false })
      .then(function (xlsBuffer) {
        const a = document.createElement('a');
        const blob = new Blob([xlsBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);

        a.href = url;
        a.download = (data.filename ? data.filename : 'export') + '.xlsx';
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.message);
      });
  },
};

export default tool_app;
